@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Archivo", sans-serif;
}
.homepage .snn-logo {
  width: 150px;
  margin: 20px;
}
.herosection h1 {
  font-weight: 800;
  font-size: 60px;
  color: #252d16;
}
.herosection p {
  font-weight: 600;
  font-size: 20px;
}

.btn {
  background-color: #252d16;
  color: #fff;
  border: 2px solid #252d16 !important;
}
.btn:hover {
  background-color: #252d16 !important;
}
.btn:active,
.btn:focus {
  background-color: #fff !important;
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
  border: 2px solid #252d16 !important;
  color: #252d16 !important;
}
.herosection .coming_soon {
  height: 600px;
}
.herosection .enq-btn {
  color: #fff;
}
.herosection i {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.363);
}
.disclimer p {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.503);
}

.slider h1 {
  color: #252d16;
  font-size: 40px;
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 2px solid #252d1633;
}

.masterplan {
  margin-top: 50px;
}
.masterplan h1 {
  font-weight: 700;
  font-size: 40px;
  padding-bottom: 10px;
  color: #252d16;
  border-bottom: 2px solid #252d1633;
}

.villalayout .heading_title {
  font-size: 30px;
  font-weight: 700;
  color: #252d16;
  text-align: center;
  margin-top: 10px;
}
.villalayout .nav-pills {
  display: flex;
  flex-wrap: nowrap;
}
.villalayout .nav-link {
  background-color: #252d16;
  color: #fff;
  margin-right: 5px;
  margin-bottom: 10px;
  border: 1px solid #252d16;
  font-size: 15px;
}
.villalayout .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #fff !important;
  color: #252d16;
  border: 1px solid #252d16;
}
.villalayout .floor_title {
  font-weight: 500;
  font-size: 18px;
}
.villalayout .floor_desc {
  font-size: 17px;
}
.villalayout .floor_desc span {
  font-size: 13px;
}
.flat_facing {
  padding-top: 0px;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 20px;
}

.location {
  margin-top: 50px;
}
.location h1 {
  font-size: 40px;
  font-weight: 700;
  color: #252d16;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 50px;
  border-bottom: 2px solid #252d1633;
}
.location img {
  height: 700px;
  object-fit: cover;
  width: 100%;
}
/* .location img{} */
/*  */

.contact h1 {
  font-size: 30px;
  font-weight: 700;
  color: #252d16;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 50px;
  border-bottom: 2px solid #252d1633;
}

.contact .form-control {
  border: 1px solid rgba(0, 0, 0, 0.121);
  color: #000;
  border-radius: 2px;
}

.contact .form-control:active,
.contact .form-control:focus,
.contact .form-control:focus:active {
  outline: black !important;
  box-shadow: none;
  border-radius: 0px;
  border: 1px solid #252d16;
}
@media (max-width: 800px) {
  .herosection .snn-logo {
    width: 250px;
  }
  .herosection h1 {
    font-size: 40px;
  }
  .location img {
    height: auto;
    width: auto;
  }
}

.mockupvilla h1 {
  font-size: 30px;
  font-weight: 700;
  color: #252d16;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 50px;
  border-bottom: 2px solid #252d1633;
}
.mockupvilla video {
  width: 100%;
  max-height: 800px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
